import { Controller } from "react-hook-form";
import SelectSearch from "react-select-search";

import classNames from "classnames";
import { Trans } from "@lingui/macro";

import Field from "components/field/field";
import FieldFile from "components/field-file/field-file";

function FormFields({ item, className, control, errors, resetField }: any) {
  return (
    <div className={className}>
      {item.Type !== "checkbox" && <label>{item.Label}</label>}
      <Controller
        name={item.name}
        control={control}
        rules={{ required: !item.Optional }}
        render={({ field }) => {
          switch (item.Type) {
            case "select":
              return item.SelectOptions ? (
                <SelectSearch
                  options={item.SelectOptions}
                  onChange={field.onChange}
                  value={field.value}
                  className={classNames("select-search", "select-search-form", {
                    error: errors[item.name],
                  })}
                  placeholder={item.Placeholder || "-"}
                />
              ) : (
                <></>
              );
            case "textarea":
              return (
                <div className="form-control">
                  <textarea
                    onChange={field.onChange}
                    placeholder={item.Placeholder}
                    value={field.value}
                    className={classNames("field field--message", {
                      error: errors[item.name],
                    })}
                  ></textarea>
                </div>
              );
            case "checkbox":
              return (
                <div className="form-check">
                  <Field
                    onChange={field.onChange}
                    placeholder={item.Placeholder}
                    type={item.Type}
                    value={field.value}
                    className={classNames({ error: errors[item.name] })}
                    id={item.name}
                  />
                  <label
                    className={classNames(`form-check-label`, {
                      error: errors[item.name],
                    })}
                    htmlFor={item.name}
                  >
                    {item.Label}
                  </label>
                </div>
              );
            case "file":
              return (
                <FieldFile
                  onChange={field.onChange}
                  placeholder={
                    <Trans>
                      <strong>Add file</strong> or drop files here
                    </Trans>
                  }
                  resetField={resetField}
                  value={field.value}
                  className={classNames({ error: errors[item.name] })}
                />
              );
            default:
              return (
                <Field
                  onChange={field.onChange}
                  placeholder={item.Placeholder}
                  type={item.Type}
                  value={field.value}
                  className={classNames({ error: errors[item.name] })}
                />
              );
          }
        }}
      />
    </div>
  );
}

export default FormFields;
