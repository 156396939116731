/**
 * External dependencies.
 */
import Image from "next/image";
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import BtnLink from "components/btn-link/btn-link";
import { getStrapiMedia } from "utils";
import { MediaProp } from "types";
import Markdown from "components/markdown/markdown";

interface AlternatingProp {
  data: {
    Title: string;
    Subtitle?: string;
    Content?: string;
    AlignImage?: string;
    LinkText?: string;
    LinkURL?: string;
    RelNoFollow: boolean;
    OpenInNewTab: boolean;
    LinkColorHex?: string;
    Box?: BoxProp[];
    Image?: MediaProp;
    LayoutStyle?: boolean;
  };
}

interface BoxProp {
  id: number;
  Title?: string;
  Content?: string;
  Media?: MediaProp;
}

const Alternating: React.FC<AlternatingProp> = ({ data }) => {
  return (
    <div className="alternate">
      <div className="container">
        <div
          className={classNames(
            "alternate__row",
            data.LayoutStyle && "alternate__layout"
          )}
        >
          <div
            className={classNames(
              "alternate__box alternate__box--image",
              `alternate__box--${data.AlignImage}`
            )}
          >
            {data.Image && data.Image.data && (
              <Image
                alt=""
                src={getStrapiMedia(data.Image)}
                width={data.Image.data.attributes.width}
                height={data.Image.data.attributes.height}
              />
            )}
          </div>
          <div
            className={classNames(
              "alternate__box alternate__box--content",
              `alternate__box--${data.AlignImage}`
            )}
          >
            {data.Subtitle && (
              <p
                className="alternate__subtitle"
                style={{ color: data.LinkColorHex }}
              >
                {data.Subtitle}
              </p>
            )}
            <h3>{data.Title}</h3>
            {data.Content && <Markdown content={data.Content} />}
            {data.Box &&
              data.Box.map((item) => (
                <div className="alternate__item" key={item.id}>
                  <div className="alternate__item-title">
                    <div className="alternate__item-icon">
                      {item.Media?.data && (
                        <Image
                          alt=""
                          src={getStrapiMedia(item.Media)}
                          width={24}
                          height={24}
                        />
                      )}
                    </div>
                    <span>{item.Title}</span>
                  </div>
                  {item.Content && <Markdown content={item.Content} />}
                </div>
              ))}
            {data.LinkURL && data.LinkText && (
              <BtnLink
                href={data.LinkURL}
                color={data.LinkColorHex}
                RelNoFollow={data.RelNoFollow}
                OpenInNewTab={data.OpenInNewTab}
              >
                {data.LinkText}
              </BtnLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alternating;
