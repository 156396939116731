/**
 * External dependencies
 */
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";

/**
 * Internal dependencies
 */
import SliderElementImage from "./slider-element-image";
import SliderElementProject from "./slider-element-project";
import SliderElementTestimonial from "./slider-element-testimonial";
import SliderElementArticle from "./slider-element-article";
import Link from "../../components/link/link";

interface SliderElementProp {
  data: any;
}

const SliderElement: React.FC<SliderElementProp> = ({ data }) => {
  const [viewportRef] = useEmblaCarousel({
    dragFree: data?.slider?.data?.attributes?.dragFree,
    containScroll: "trimSnaps",
    align: 0,
  });

  if (!data?.slider || !data.slider?.data?.attributes?.Slide.length) {
    return null;
  }

  return (
    <div
      className={classNames("slider-element", {
        "has-side-space": data?.slider?.data?.attributes?.SideSpace,
      })}
    >
      {data?.CustomTextColor && (
        <style>{`.slider-element{color:${data?.CustomTextColor}}`}</style>
      )}

      <div
        className={classNames({
          container: data?.Container,
        })}
      >
        {data?.Title && <h2>{data.Title}</h2>}

        {data?.Link?.Link && (
          <Link
            href={data.Link.Link}
            OpenInNewTab={data.Link.OpenInNewTab}
            RelNoFollow={data.Link.RelNoFollow}
            className={classNames({
              "btn btn--fill": data.Link.Button,
            })}
          >
            {data.Link.Text}
          </Link>
        )}

        <div className="embla__viewport" ref={viewportRef}>
          <div className="embla__container">
            {data.slider?.data?.attributes?.Slide?.map(
              (item: any, index: number) => {
                switch (item.__component) {
                  case "general.image-link":
                    return <SliderElementImage key={index} item={item} />;
                  case "sections.testimonial":
                    return <SliderElementTestimonial key={index} item={item} />;
                  case "sections.project":
                    return <SliderElementProject key={index} project={item} />;
                  case "sections.article":
                    return <SliderElementArticle key={index} article={item} />;
                }
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderElement;
