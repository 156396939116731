/**
 * External dependencies
 */
import classNames from "classnames";
import { toast } from "react-toastify";

/**
 * Internal dependencies
 */
import { formatBytes } from "utils";

const FILE_SIZE_LIMIT = 10485760; // 10mb

interface FieldFileProp {
  placeholder: string | JSX.Element;
  onChange: any;
  resetField: any;
  value: {
    name: string;
  };
  className?: string;
}

const FieldFile: React.FC<FieldFileProp> = ({
  placeholder,
  value,
  onChange,
  className,
  resetField,
}) => {
  const getFileName = value?.name;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (event.target.files[0]?.size > FILE_SIZE_LIMIT) {
        resetField(value.name);
        event.target.value = "";
        toast.error(
          `File size is too large. Max size is ${formatBytes(FILE_SIZE_LIMIT)}.`
        );
        return;
      }
      if (event.target.files[0]) {
        onChange(event.target.files[0]);
      }
    }
  };

  return (
    <div className="form-control">
      <div className="field field--file">
        <div className="field__placeholder">
          {value ? getFileName : placeholder}
        </div>
        <input
          type="file"
          onChange={handleFileChange}
          className={classNames("field__input", className)}
        />
      </div>
      <small>
        Max file size: <strong>{formatBytes(FILE_SIZE_LIMIT)}</strong>
      </small>
    </div>
  );
};

export default FieldFile;
