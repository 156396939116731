/**
 * External dependencies.
 */
import Image from "next/image";
import { createElement } from "react";
import { MediaArrProp } from "types";

/**
 * Internal dependencies.
 */
import { getStrapiMediaArr } from "utils";

interface LogosProp {
  logos: LogoItemProp[];
  item?: any;
}

interface LogoItemProp {
  media: MediaArrProp;
}

const Logos: React.FC<LogosProp> = ({ logos, item }) => {
  if (item.hide) {
    return null;
  }
  const heading = createElement(item.Type || "h6", {}, item.LogosTitle);

  return (
    <div className="logos">
      <div className="container container--small">
        <div
          className="logos__inner"
          style={item.TitleColorHEX ? { color: item.TitleColorHEX } : {}}
        >
          {heading}
        </div>
        <div className="logos__items">
          {logos?.map((item: LogoItemProp, index: number) => (
            <div key={index} className="logo">
              {item && <img src={getStrapiMediaArr(item)} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Logos;
