import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePathname } from "next/navigation";

import { fetchAPI } from "utils";
import type { FormBuilderProp, FormFieldProp } from "types/forms";
import FormFields from "./form-fields";
import Markdown from "components/markdown/markdown";
import { useRouter } from "next/router";

interface FormBuilderProps extends FormBuilderProp {
  slug: string;
}

const FormBuilder: React.FC<FormBuilderProps> = ({ data, slug }) => {
  const router = useRouter();
  const pathname = usePathname();
  const isSubmissionPage = pathname.includes("thank-you");

  const names = data?.FormField?.reduce((acc: any, val: any) => {
    acc[val.name] = "";
    return acc;
  }, {});

  let defaultValues = {
    ...names,
    source: slug,
  };

  data?.FormField.forEach((item: FormFieldProp) => {
    if (item.Type === "checkbox") {
      defaultValues[item.name] = false;
    } else if (item.Type === "select") {
      defaultValues[item.name] = null;
    }
  });

  const {
    reset,
    resetField,
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<any> = (submitData) => {
    console.log("submitData", submitData);
    const newData = new FormData();
    const dataObj: any = {
      publishedAt: null,
      source: submitData.source,
      hooks: data?.ZapierHooks || [],
      sendEmail: data.SendEmail,
    };

    data?.FormField.map((item: FormFieldProp) => {
      if (item.Type !== "file") {
        dataObj[item.name] = submitData[item.name];
      } else if (submitData[item.name]) {
        newData.append(
          `files.${item.name}`,
          submitData[item.name],
          submitData[item.name].name
        );
      }
    });

    newData.append("data", JSON.stringify(dataObj));

    console.log(submitData);

    return fetchAPI(
      "/dynamic-form-entries/submit",
      {},
      {
        method: "post",
        headers: {},
        body: newData,
      }
    )
      .then((res) => {
        if (res.error) {
          toast.error("There was an error submitting the form!");
          return;
        }
        toast("Form submitted!");
        if (data?.FormSubmission && data?.FormSubmission.Message) {
          setTimeout(() => {
            router.push("/thank-you" + pathname);
          }, 1000);
        }
        reset();
      })
      .catch(() => {
        toast.error("There was an error submitting the form!");
      });
  };

  return (
    <>
      {data?.CustomCSS && data?.CustomCSS !== "" && (
        <style>{data?.CustomCSS}</style>
      )}
      {isSubmissionPage &&
        data?.FormSubmission &&
        data?.FormSubmission.CodeSnippets && (
          <div
            dangerouslySetInnerHTML={{
              __html: data?.FormSubmission.CodeSnippets,
            }}
          />
        )}
      <div className="form--builder">
        <div className="container">
          <h5>{data?.Title}</h5>
          {data?.Content && (
            <div className="form--builder__subtitle">
              <Markdown content={data?.Content} />
            </div>
          )}
          {isSubmissionPage && data?.FormSubmission && (
            <p className="form--builder__submission--message">
              {data?.FormSubmission.Message}
            </p>
          )}
          {!isSubmissionPage && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" {...register("source")} />

              <div className="form--builder__grid">
                {data?.FormField &&
                  data?.FormField.map((item: FormFieldProp, key: number) => (
                    <FormFields
                      item={item}
                      key={key}
                      className="form--builder__element form--builder__grid-column-full"
                      control={control}
                      errors={errors}
                      resetField={resetField}
                    />
                  ))}
              </div>
              <button
                className="btn btn--fill"
                type="submit"
                disabled={isSubmitting}
              >
                {data?.SubmitText}
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default FormBuilder;
